import AddIcon from '@mui/icons-material/Add'
import AddTaskIcon from '@mui/icons-material/AddTask'
import CloseIcon from '@mui/icons-material/Close'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled'
import { Box, Fab, Fade } from '@mui/material'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NeedHelpModal } from '../../../components/NeedHelpModal'
import { selectUserState, stopUserActivityAsync, useAppDispatch, useAppSelector } from '../../../store'
import { SpeedDialItem } from './SpeedDialItem'

const OVERLAY_Z_INDEX = 1300
const CONTENT_Z_INDEX = 1301

const AppSpeedDial = ({
  setOpenBottomDrawer,
}: {
  setOpenBottomDrawer: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const [needHelpOpen, setNeedHelpOpen] = useState(false)
  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(selectUserState)

  const handleOpen = () => setSpeedDialOpen(!speedDialOpen)
  const handleClose = () => setSpeedDialOpen(false)

  const addTask = () => {
    setOpenBottomDrawer(true)
    handleClose()
  }

  const takeABreak = () => {
    dispatch(stopUserActivityAsync(user.id))
    handleClose()
  }

  const needHelp = () => {
    setNeedHelpOpen(true)
    handleClose()
  }

  // Memoize styles that depend on speedDialOpen
  const styles = useMemo(
    () => ({
      overlay: {
        position: 'fixed' as const,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.90)',
        zIndex: speedDialOpen ? OVERLAY_Z_INDEX : 0,
        pointerEvents: 'auto' as const,
        touchAction: 'none' as const,
      },
      container: {
        position: 'fixed',
        bottom: '1.5em',
        right: '1.5em',
        transform: 'translateZ(0px)',
        display: 'flex',
        alignItems: 'end',
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: speedDialOpen ? CONTENT_Z_INDEX : 0,
      },
      menu: {
        width: '20em',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'flex-end',
        flexDirection: 'column',
        marginBottom: '1em',
        position: 'relative',
        zIndex: speedDialOpen ? CONTENT_Z_INDEX : 0,
      },
      fab: {
        borderRadius: '10%',
        height: '5.5vh',
        width: '12vw',
        position: 'relative',
        zIndex: speedDialOpen ? CONTENT_Z_INDEX : 0,
      },
    }),
    [speedDialOpen],
  )

  return (
    <>
      {speedDialOpen && (
        <Fade in={speedDialOpen} unmountOnExit>
          <Box onClick={handleClose} style={styles.overlay} />
        </Fade>
      )}
      <Box sx={styles.container}>
        {speedDialOpen && (
          <Fade in={speedDialOpen} unmountOnExit>
            <Box sx={styles.menu}>
              <SpeedDialItem
                icon={<AddTaskIcon fontSize="large" style={{ color: '#FFAB48' }} />}
                text={t('tasks.speedDial.addTask')}
                handleClick={addTask}
              />
              <SpeedDialItem
                icon={<PauseCircleFilledIcon fontSize="large" style={{ color: '#999999' }} />}
                text={t('tasks.speedDial.stopAll')}
                handleClick={takeABreak}
              />
              <SpeedDialItem
                icon={<ConnectWithoutContactIcon fontSize="large" style={{ color: '#000000' }} />}
                text={t('tasks.speedDial.iNeedHelp')}
                handleClick={needHelp}
              />
            </Box>
          </Fade>
        )}

        <Fab color="primary" aria-label="add" onClick={handleOpen} sx={styles.fab}>
          {speedDialOpen ? <CloseIcon /> : <AddIcon />}
        </Fab>

        <NeedHelpModal openHelpModal={needHelpOpen} setOpenHelpModal={setNeedHelpOpen} />
      </Box>
    </>
  )
}

export default AppSpeedDial
