import { Alert, Box, Divider, Grid } from '@mui/material'
import { CreateTaskForm, PartialCreateSubtaskRequest } from 'api/dtos'
import { SetState } from 'common/types/types'
import { SubtaskCreationForm } from 'components/SubtaskCreationForm'
import ReinforcementsPage from 'pages/Reinforcements/ReinforcementsPage'
import { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SelectedReinforcement, setSelectedReinforcements } from 'store/reinforcements-slice'
import { genUniqueId } from 'utils/utils'
import {
  RootState,
  createTaskAsync,
  selectTaskListState,
  selectUserState,
  useAppDispatch,
  useAppSelector,
} from '../../../store'
import { TaskAlertContext } from '../TasksPage'
import { SharedSubtaskProvider } from '../UseSharedSubtaskContext'
import { BottomSection } from './BottomSection'
import { TitleSection } from './TitleSection'
import { TopSection } from './TopSection'

export const TaskForm = ({ setOpen }: { setOpen: SetState<boolean> }) => {
  const { user } = useAppSelector(selectUserState)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { error } = useAppSelector(selectTaskListState)
  const taskControl = useForm<CreateTaskForm>()
  const initialUuid = genUniqueId()
  const [subtasksToCreate, setSubTasksToCreate] = useState<PartialCreateSubtaskRequest[]>([
    {
      description: '',
      difficulty: undefined,
      uuid: initialUuid,
      deadline: null,
    },
  ])
  const { alertSuccess } = useContext(TaskAlertContext)

  const [reinforcementOpen, setReinforcementOpen] = useState(false)
  const localReinforcements = useSelector((state: RootState) => state.reinforcements.selectedReinforcementsObj)
  const reinforcementsToAttachToTask = localReinforcements.filter(r => r.selected).map(r => r.reinforcement.id)
  const [topTitle, setTopTitle] = useState(() => t('tasks.newTask'))
  const [showTitleOnly, setShowTitleOnly] = useState(true)
  const [expandedUuid, setExpandedUuid] = useState<string | null>(initialUuid)
  const [stepTextFieldValues, setStepTextFieldValues] = useState<{ [key: string]: string }>({})

  // state for subtask reinforcements dynamic for every subtask
  const [activeSubtaskUuid, setActiveSubtaskUuid] = useState<string | null>(null)

  const changeReinforcements = useCallback((reinforcements: SelectedReinforcement[]) => {
    setSelectedReinforcements(reinforcements)
  }, [])

  const isFormValid = taskControl.formState.isValid
  const resetForm = useCallback(() => {
    let newUuid = genUniqueId()
    setSubTasksToCreate([
      {
        description: '',
        difficulty: undefined,
        uuid: newUuid,
        deadline: null,
      },
    ])
    setExpandedUuid(newUuid)
    setTopTitle(t('tasks.newTask'))
    setStepTextFieldValues({}) // If you want to reset the titleValue state
    taskControl.reset()
    setActiveSubtaskUuid(null)
  }, [taskControl, t])

  const onTaskSubmit = useCallback(
    (data: CreateTaskForm) => {
      dispatch(
        createTaskAsync({
          title: topTitle,
          subtasks: data.subtasks,
          userId: user.id,
          reinforcementIds: reinforcementsToAttachToTask,
        }),
      ).then(() => {
        setOpen(false)
        resetForm()
        setShowTitleOnly(true)
        alertSuccess('משימה נוספה בהצלחה')
      })
    },
    [dispatch, user, setOpen, alertSuccess, reinforcementsToAttachToTask, resetForm, topTitle],
  )

  const validateAndSubmitTask = useCallback(
    (data: CreateTaskForm) => {
      const incompleteSubtaskIndex = data.subtasks.findIndex(
        subtask => !subtask.description || !subtask.deadline || !subtask.difficulty,
      )

      if (incompleteSubtaskIndex !== -1) {
        setExpandedUuid(subtasksToCreate[incompleteSubtaskIndex].uuid)
      } else {
        onTaskSubmit(data)
      }
    },
    [subtasksToCreate, setExpandedUuid, onTaskSubmit],
  )

  const handleFormSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      // Trigger form validation
      taskControl.trigger().then(isValid => {
        // Always call validateAndSubmitTask, regardless of form validation
        const formData = taskControl.getValues()
        validateAndSubmitTask(formData)
      })
    },
    [taskControl, validateAndSubmitTask],
  )

  return (
    <SharedSubtaskProvider
      setReinforcements={changeReinforcements}
      subtasksToCreate={subtasksToCreate}
      setSubTasksToCreate={setSubTasksToCreate}
      taskControl={taskControl}
      setOpen={setOpen}
      onSubmit={onTaskSubmit}
      setReinforcementOpen={setReinforcementOpen}
      reinforcementOpen={reinforcementOpen}
      topTitle={topTitle}
      setTopTitle={setTopTitle}
      setExpandedUuid={setExpandedUuid}
      stepTextFieldValues={stepTextFieldValues}
      setStepTextFieldValues={setStepTextFieldValues}
      setActiveSubtaskUuid={setActiveSubtaskUuid}
      activeSubtaskUuid={activeSubtaskUuid}
      validateAndSubmit={handleFormSubmit}
      expandedUuid={expandedUuid}
      resetForm={resetForm}
    >
      <Box component="form" onSubmit={handleFormSubmit} noValidate sx={{ height: '100%', width: '100%' }}>
        {reinforcementOpen ? (
          <ReinforcementsPage />
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <TopSection
                onClose={() => setShowTitleOnly(true)}
                setShowTitleOnly={setShowTitleOnly}
                resetForm={resetForm}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {showTitleOnly ? (
              <Grid item xs={12}>
                <TitleSection isFormValid={isFormValid} onContinue={() => setShowTitleOnly(false)} />
              </Grid>
            ) : (
              <>
                <SubtaskCreationForm />
                <Grid item xs={12}>
                  <BottomSection />
                </Grid>
              </>
            )}
          </Grid>
        )}
        {error && (
          <Alert sx={{ marginTop: 2 }} severity="error">
            {error}
          </Alert>
        )}
      </Box>
    </SharedSubtaskProvider>
  )
}
