import { Box, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getMedalImage, MedalImages } from '../SuccessGridItem'
import motivationalSentences from './motivationalSentences.json'

export interface AnimatedSuccessImageProps {
  subtask: {
    endTime: Date | undefined
    deadline: Date | undefined
    difficulty: 'EASY' | 'MEDIUM' | 'HARD'
  }
}

export const AnimatedSuccessImage: React.FC<AnimatedSuccessImageProps> = ({ subtask }) => {
  const [animationPhase, setAnimationPhase] = useState<'box' | 'medal' | 'complete'>('box')
  const [startPosition, setStartPosition] = useState({ top: 0, left: 0 })
  const boxRef = useRef<HTMLDivElement>(null)
  const [motivationalText, setMotivationalText] = useState('')

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * motivationalSentences.finishedTaskSentences.length)
    setMotivationalText(motivationalSentences.finishedTaskSentences[randomIndex])

    const boxTimer = setTimeout(() => {
      if (boxRef.current) {
        const imgRect = boxRef.current.querySelector('.medal-image')?.getBoundingClientRect()
        if (imgRect) {
          setStartPosition({
            top: imgRect.top + window.scrollY,
            left: imgRect.left + imgRect.width / 2,
          })
        }
      }
      setAnimationPhase('medal')
    }, 1500)
    const completeTimer = setTimeout(() => setAnimationPhase('complete'), 3000)

    return () => {
      clearTimeout(boxTimer)
      clearTimeout(completeTimer)
    }
  }, [])

  const getCategory = (subtask: AnimatedSuccessImageProps['subtask']): keyof MedalImages => {
    if (subtask.difficulty === 'HARD') return 'hard'
    if (subtask.endTime && subtask.deadline) {
      const endDate = new Date(subtask.endTime).toISOString().split('T')[0]
      const deadlineDate = new Date(subtask.deadline).toISOString().split('T')[0]
      if (endDate < deadlineDate) return 'early'
      if (endDate === deadlineDate) return 'onTime'
    }
    return 'onTime' // Default case
  }

  const category = getCategory(subtask)
  const medalImage = getMedalImage(category, subtask.difficulty)

  return (
    <>
      {animationPhase === 'box' && (
        <Box className="success-box" ref={boxRef}>
          <img src={medalImage} alt={`${category} medal`} className="medal-image" />
          <Typography variant="h6" className="motivational-text" dir="rtl">
            {motivationalText}
          </Typography>
        </Box>
      )}
      {animationPhase === 'medal' && (
        <img
          src={medalImage}
          alt={`Moving and growing ${category} medal`}
          className="moving-growing-image"
          style={
            {
              top: `${startPosition.top}px`,
              left: `${startPosition.left}px`,
              '--end-top': '12vh',
              '--end-left': '16vw',
            } as React.CSSProperties
          }
        />
      )}
    </>
  )
}
