import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, Button, Typography } from '@mui/material'
import { differenceInDays, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontalScroller } from '../../components/HorizontalScroller'
import { selectTaskListState, selectUserState, useAppDispatch, useAppSelector } from '../../store'
import { listSuccessesAsync, selectSuccessListState } from '../../store/successes-slice'
import SuccessGridItem from './SuccessGridItem'

export const SuccessesGridView = () => {
  const { t } = useTranslation()
  const { successes } = useAppSelector(selectSuccessListState) // eslint-disable-line @typescript-eslint/no-unused-vars
  const { user } = useAppSelector(selectUserState) // eslint-disable-line @typescript-eslint/no-unused-vars
  const { tasks } = useAppSelector(selectTaskListState)
  const isMobile = useAppSelector(state => state.layout.isMobileView)
  const dispatch = useAppDispatch()
  const [expandedCategories, setExpandedCategories] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    dispatch(listSuccessesAsync(user.id))
  }, [dispatch, user.id])

  const calculateTimeDifference = (startTime: string, endTime: string) => {
    const start = parseISO(startTime)
    const end = parseISO(endTime)
    const daysDifference = differenceInDays(end, start)
    return daysDifference
  }

  const getTaskTitleById = (taskId: number): string => {
    const task = tasks?.find(task => task.id === taskId)
    return task ? task.title : `Couldn't find task`
  }

  const toggleCategory = (category: string) => {
    setExpandedCategories(prevState => ({
      ...prevState,
      [category]: !prevState[category],
    }))
  }

  const renderSuccessCategory = (categoryName: 'hardSubtasks' | 'earlySubtasks' | 'onTimeSubtasks', title: string) => {
    const categoryItems = successes?.[categoryName]
    if (categoryItems && categoryItems.length > 0) {
      const sortedItems = [...categoryItems].sort((a, b) => {
        const dateA = parseISO(a.endTime?.toString())
        const dateB = parseISO(b.endTime?.toString())
        return dateB.getTime() - dateA.getTime()
      })

      const isExpanded = expandedCategories[categoryName]
      const displayItems = isExpanded ? sortedItems : sortedItems.slice(0, 5)
      const hasMore = sortedItems.length > 5

      return (
        <Box>
          <Typography component="h1" variant="h4" fontSize={'1.5em'} marginRight={'1em'}>
            {title}
          </Typography>
          <HorizontalScroller
            itemsList={displayItems.map((item, index) => (
              <SuccessGridItem
                key={index}
                subtaskTitle={item.description}
                timeDifference={calculateTimeDifference(item.endTime.toString(), item.deadline.toString())}
                taskTitle={getTaskTitleById(item.taskId)}
                category={categoryName.replace('Subtasks', '') as 'hard' | 'early' | 'onTime'}
                difficulty={item.difficulty.toString()}
              />
            ))}
            fetchMoreDataFunction={() => {}}
          />
          {hasMore && (
            <Button
              onClick={() => toggleCategory(categoryName)}
              sx={{ color: 'black', mr: '1em' }}
              startIcon={isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            >
              {isExpanded ? 'צמצום הרשימה' : 'כל ההצלחות שלך'}
            </Button>
          )}
        </Box>
      )
    }
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: isMobile ? 'right' : 'center',
        gap: 2,
      }}
    >
      {renderSuccessCategory('hardSubtasks', t('successes.hard'))}
      {renderSuccessCategory('earlySubtasks', t('successes.early'))}
      {renderSuccessCategory('onTimeSubtasks', t('successes.onTime'))}
    </Box>
  )
}
export default SuccessesGridView
