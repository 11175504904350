import { Box, Typography } from '@mui/material'
import { AboutUsModal } from 'components/AboutUsModal'
import DrawerItem from 'components/DrawerItem'
import { useCallback, useState } from 'react'
import { logout, useAppDispatch } from 'store'
import packageJson from '../../../../package.json'
import logo from '../../../icons/AniAlZeLogo.png'

export default function MenteeSideDrawerContent() {
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useAppDispatch()
  const logOut = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  function linkToWebsite(url: string) {
    window.open(url, '_blank')
  }

  function aboutUsModal() {
    setModalOpen(true)
  }

  return (
    <>
      <img src={logo} alt={logo} style={{ width: '10em', height: '10em' }} />

      <div style={{ flexGrow: 1, width: '100%' }} />

      <DrawerItem
        eventHandler={() =>
          linkToWebsite('https://drive.google.com/drive/folders/1N6khzWXdf9ESp6gMcfL7jgETGlxMcdoK?usp=drive_link')
        }
        title={'דפי מידע'}
      />

      <DrawerItem eventHandler={aboutUsModal} title={'עלינו'} />

      <DrawerItem
        eventHandler={() => linkToWebsite('https://forms.monday.com/forms/e7464e9cbe067daf6215f59e6f3f885c?r=use1')}
        title={'דיווח על באג'}
      />

      <DrawerItem eventHandler={logOut} title={'התנתקות'} />

      <div style={{ flexGrow: 7, width: '100%' }} />

      <Typography variant={'body1'} style={{ flexGrow: 1 }}>
        {'גרסה ' + packageJson.version}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          flexGrow: 0.5,
        }}
      >
        <Typography
          variant={'body2'}
          style={{ flexGrow: 1, margin: '0 0.3em 0 0.3em' }}
          onClick={() =>
            linkToWebsite(
              'https://docs.google.com/document/d/1KG3QF4LIxQW4v-fcr_LAWRImjj9a9L8R/edit?usp=sharing&ouid=110292334124987963876&rtpof=true&sd=true',
            )
          }
        >
          תנאי שימוש
        </Typography>
        <Typography
          variant={'body2'}
          style={{ flexGrow: 1, margin: '0 0.3em 0 0.3em' }}
          onClick={() =>
            linkToWebsite(
              'https://docs.google.com/document/d/1MhPe65OKI6I0Z4ofVYw8GdbxQPK6I_AT/edit?usp=sharing&ouid=110292334124987963876&rtpof=true&sd=true',
            )
          }
        >
          מדיניות פרטיות
        </Typography>
      </Box>

      <AboutUsModal handleClose={() => setModalOpen(false)} open={modalOpen} />
    </>
  )
}
