import { Button, Grid } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { genUniqueId } from 'utils/utils'
import { SharedSubtaskContext } from '../UseSharedSubtaskContext'

export const BottomSection = () => {
  const { setExpandedUuid, setSubTasksToCreate, subtasksToCreate, validateAndSubmit } = useContext(SharedSubtaskContext)
  const { t } = useTranslation()

  const addNewSubtask = () => {
    const newUuid = genUniqueId()
    const newSubtasks = [
      ...subtasksToCreate,
      { description: '', difficulty: undefined, uuid: newUuid, deadline: new Date() },
    ]
    setSubTasksToCreate!(newSubtasks)
    setExpandedUuid(newUuid)
  }

  return (
    <Grid container justifyContent="space-between" sx={{ marginTop: '1em', height: '7dvh' }}>
      <Grid item xs={6} textAlign="center">
        <Button
          onClick={validateAndSubmit}
          variant="contained"
          sx={{
            backgroundColor: '#FFC888',
            boxShadow: '0px 0px 3px rgba(45, 45, 45, 0.25)',
            borderRadius: '0.4em',
            width: '80%',
          }}
          disabled={subtasksToCreate.length === 0}
        >
          {t('tasks.done')}
        </Button>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#FFC888',
            boxShadow: '0px 0px 3px rgba(45, 45, 45, 0.25)',
            borderRadius: '0.4em',
            width: '80%',
          }}
          onClick={addNewSubtask}
        >
          {t('subtasks.addSubtask')}
        </Button>
      </Grid>
    </Grid>
  )
}
