import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import SaveIcon from '@mui/icons-material/Save'
import { IconButton, MenuItem, Select, SelectChangeEvent, Stack, Switch, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Buffer } from 'buffer'
import MentorNameWhatsApp from 'components/MentorNameWhatsApp'
import { ProcrastinationProfile } from 'components/ProcrastinationProfile'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { EfficiencyTimes, Role } from '../../api/dtos'
import { DEFAULT_AVATAR_BASE } from '../../common/consts'
import ProfilePictureCircle from '../../common/icons/common/ProfilePictureCircle'
import {
  listAllMenteesAsync,
  listMentorsAsync,
  selectUserState,
  updateUserAsync,
  useAppDispatch,
  useAppSelector,
} from '../../store'
import AvatarUploader from './AvatarUploader'

export const EditProfile = () => {
  const dispatch = useAppDispatch()
  const { user, procrastinations, mentors } = useAppSelector(selectUserState)
  const myMentor = mentors?.find(value => value.id === user.mentorId)
  const [editProfile, setEditProfile] = useState(false)
  const [editProfilePicture, setEditProfilePicture] = useState(false)
  const [efficiencyTimeValue, setEfficiencyTimeValue] = useState(user.efficiencyTime)
  const [enableNotifications, setEnableNotifications] = useState(user.enableNotifications)
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)
  const [name, setName] = useState(user.name)
  const { register, handleSubmit, getValues } = useForm()
  const isMobile = useAppSelector(state => state.layout.isMobileView)

  useEffect(() => {
    dispatch(listMentorsAsync())
    user.role === Role.Mentor && dispatch(listAllMenteesAsync())
  }, [user.id, dispatch, user.role])

  let base64String = DEFAULT_AVATAR_BASE

  if (user.profilePictureBytea != null) {
    base64String = Buffer.from(user.profilePictureBytea.data).toString('base64')
  }

  const handleSelectEfficiencyTimeChange = (event: SelectChangeEvent) => {
    setEfficiencyTimeValue(event.target.value as EfficiencyTimes)
  }

  const onSubmit = useCallback(
    (data: any) => {
      const formData = {
        id: user.id,
        name: data.nameForm || name,
        role: user.role,
        mentorId: data.mentorIDform || user.mentorId,
        efficiencyTime: efficiencyTimeValue,
        profilePictureURL: 'TBD',
        enableNotifications: enableNotifications,
        phoneNumber: data.phoneNumberForm || phoneNumber,
      }
      setEditProfile(false)
      dispatch(updateUserAsync(formData))
    },
    [dispatch, user, name, efficiencyTimeValue, enableNotifications, phoneNumber],
  )

  const handleNotificationToggle = useCallback(() => {
    const newNotificationState = !enableNotifications
    setEnableNotifications(newNotificationState)

    if (editProfile) return
    // Get current form values
    const currentFormValues = getValues()

    dispatch(
      updateUserAsync({
        id: user.id,
        name: currentFormValues.nameForm || name,
        role: user.role,
        mentorId: currentFormValues.mentorIDform || user.mentorId,
        efficiencyTime: currentFormValues.efficiencyTimes || efficiencyTimeValue,
        profilePictureURL: 'TBD',
        enableNotifications: newNotificationState,
        phoneNumber: currentFormValues.phoneNumberForm || phoneNumber,
      }),
    )
  }, [
    dispatch,
    enableNotifications,
    user.id,
    user.role,
    getValues,
    name,
    efficiencyTimeValue,
    phoneNumber,
    user.mentorId,
    editProfile,
  ])

  function translateEfficiencyTimes(efficiencyTime: string | undefined) {
    switch (efficiencyTime) {
      case EfficiencyTimes.MORNING:
        return 'בוקר'
      case EfficiencyTimes.NOON:
        return 'צהריים'
      case EfficiencyTimes.EVENING:
        return 'ערב'
      default:
        return 'לא ידוע'
    }
  }

  const handleReset = useCallback(() => {
    // Reset all state values back to original user values
    setName(user.name)
    setPhoneNumber(user.phoneNumber)
    setEfficiencyTimeValue(user.efficiencyTime)
    setEnableNotifications(user.enableNotifications)
    setEditProfile(false)
  }, [user.name, user.phoneNumber, user.efficiencyTime, user.enableNotifications])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'auto',
        padding: '0 5% 5% 5%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {editProfilePicture ? (
          <AvatarUploader setEditState={setEditProfilePicture} />
        ) : (
          <ProfilePictureCircle imageUrl={`data:image/jpeg;base64,${base64String}`} />
        )}
        {!editProfilePicture ? (
          <Box
            sx={{
              width: '2em',
              height: '2em',
              borderRadius: '25%',
              border: '0.15em solid #FFAB48',
              backgroundColor: '#FCFCF0',
              right: '3em',
              top: '-2.5em',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              aria-label="ערוך"
              onClick={() => {
                setEditProfilePicture(true)
              }}
            >
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
          </Box>
        ) : null}
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'right',
            width: '100%',
            flexGrow: 1,
          }}
        >
          {editProfile ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'right',
                gap: '0.2em',
              }}
            >
              <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                :שם
              </Typography>
              <TextField
                {...register('nameForm')}
                variant="outlined"
                type={'text'}
                size="small"
                defaultValue={user.name}
                onChange={e => setName(e.target.value)}
                dir="rtl"
                sx={{
                  margin: '0 1em 0 0',
                  justifyContent: 'center',
                  marginBottom: '2em',
                  width: '100%',
                }}
                InputProps={{ style: { borderRadius: '0.5rem' } }}
              >
                {name}
              </TextField>
            </Box>
          ) : (
            <Typography
              component="h1"
              variant="h5"
              style={{
                width: '100%',
                textAlign: 'center',
                marginBottom: '2rem',
                marginTop: '-1.25rem',
                fontWeight: '400',
                fontSize: '1.5rem',
              }}
            >
              {user.name}
            </Typography>
          )}
          {user.role === Role.User && !editProfile && myMentor?.phoneNumber ? (
            <MentorNameWhatsApp isFixed={false} />
          ) : null}
          {!editProfile && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                mb: '2em',
              }}
            >
              <ProcrastinationProfile userProcrastinations={procrastinations} text="מה גורם לך לדחות" />
            </Box>
          )}
          <Typography variant="h5" sx={{ marginBottom: '0.5rem', fontSize: '1.25rem' }}>
            פרטי ההתקשרות שלך
          </Typography>
          {editProfile && (
            <Typography variant="h5" style={{ color: '#666', fontSize: '1rem' }}>
              :מייל
            </Typography>
          )}
          <Stack
            direction="row"
            gap={0.5}
            style={{
              display: 'flex',
              alignItems: 'center',
              direction: 'rtl',
              marginBottom: '0.5rem',
            }}
          >
            {editProfile ? (
              <TextField
                disabled
                size="small"
                value={user.email?.toString()}
                variant="outlined"
                style={{ width: '100%' }}
                InputProps={{ style: { borderRadius: '0.5rem' } }}
              ></TextField>
            ) : (
              <>
                <Typography variant="h5" style={{ color: '#666', fontSize: '1rem' }}>
                  מייל:
                </Typography>
                <Typography variant="h5" style={{ fontSize: '1rem' }}>
                  {user.email?.toString()}
                </Typography>
              </>
            )}
          </Stack>
          {editProfile && (
            <Typography variant="h5" style={{ color: '#666', fontSize: '1rem' }}>
              :טלפון
            </Typography>
          )}
          <Stack
            direction="row"
            gap={0.5}
            style={{
              display: 'flex',
              alignItems: 'center',
              direction: 'rtl',
            }}
            mb={'1.75rem'}
          >
            {editProfile ? (
              <TextField
                {...register('phoneNumberForm')}
                variant="outlined"
                type={'number'}
                size="small"
                defaultValue={user.phoneNumber}
                style={{ width: '100%' }}
                onChange={e => setPhoneNumber(e.target.value)}
                InputProps={{ style: { borderRadius: '0.5rem' } }}
              >
                {phoneNumber}
              </TextField>
            ) : (
              <>
                <Typography variant="h5" style={{ color: '#666', fontSize: '1rem' }}>
                  טלפון:
                </Typography>
                <Typography variant="h5" style={{ fontSize: '1rem' }}>
                  {phoneNumber ?? 'אין מספר במערכת'}
                </Typography>
              </>
            )}
          </Stack>
          <Typography variant="h5" sx={{ marginBottom: '0.5rem', fontSize: '1.25rem' }}>
            הגדרת הודעות
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            gap={0.5}
            style={{
              display: 'flex',
              alignItems: 'center',
              direction: 'rtl',
              marginBottom: '0.5rem',
            }}
          >
            <Typography variant="h5" style={{ color: '#666', fontSize: '1rem' }} width={'auto'}>
              מאפשר הודעות:
            </Typography>
            <Switch
              size="small"
              {...register('enableNotificationsForm')}
              checked={enableNotifications}
              onChange={handleNotificationToggle}
            />
          </Stack>
          {editProfile && (
            <Typography variant="h5" style={{ color: '#666', fontSize: '1rem' }}>
              :שעות אפקטיביות
            </Typography>
          )}
          <Stack
            direction="row"
            spacing={2}
            gap={0.5}
            style={{
              display: 'flex',
              alignItems: 'center',
              direction: 'rtl',
            }}
          >
            {editProfile ? (
              <Select
                value={efficiencyTimeValue}
                {...register('efficiencyTimes')}
                onChange={handleSelectEfficiencyTimeChange}
                style={{ width: '100%', borderRadius: '0.5rem' }}
                size="small"
                sx={{
                  '& .MuiSvgIcon-root': { left: '0', right: 'unset' },
                }}
              >
                <MenuItem sx={{ justifyContent: 'flex-end' }} value={EfficiencyTimes.MORNING.toString()}>
                  בוקר
                </MenuItem>
                <MenuItem sx={{ justifyContent: 'flex-end' }} value={EfficiencyTimes.NOON.toString()}>
                  צהריים
                </MenuItem>
                <MenuItem sx={{ justifyContent: 'flex-end' }} value={EfficiencyTimes.EVENING.toString()}>
                  ערב
                </MenuItem>
              </Select>
            ) : (
              <>
                <Typography variant="h5" style={{ color: '#666', fontSize: '1rem' }}>
                  שעות אפקטיביות:
                </Typography>
                <Typography variant="h5" style={{ fontSize: '1rem' }}>
                  {translateEfficiencyTimes(user.efficiencyTime?.toString()) || 'לא ידוע'}
                </Typography>
              </>
            )}
          </Stack>
          {editProfile ? (
            <Box
              sx={{
                mt: '1em',
                display: 'flex',
                justifyContent: 'space-evenly',
                width: '100%',
              }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ width: '40%', borderRadius: '0.5rem' }}
                endIcon={<SaveIcon />}
              >
                שמירה
              </Button>
              <Button
                onClick={handleReset}
                fullWidth
                variant="outlined"
                sx={{ width: '40%', borderRadius: '0.5rem', color: '#000', border: '1px solid #FFAB48' }}
              >
                ביטול שינויים
              </Button>
            </Box>
          ) : null}
        </form>
      </Box>

      <Box
        sx={{
          mt: '1em',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {!editProfile && (
          <Button
            onClick={() => {
              setEditProfile(true)
            }}
            sx={{ width: isMobile ? '100%' : '40%', mt: '1rem' }}
            variant="contained"
            endIcon={<ModeEditOutlineOutlinedIcon />}
          >
            עריכת פרופיל
          </Button>
        )}
      </Box>
    </Box>
  )
}
