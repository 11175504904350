import { Box, Paper, Typography } from '@mui/material'
import { useState } from 'react'
import { useAppSelector } from 'store'
import boltIcon from '../../icons/electric_bolt_icon.png'
import earlyMedal1Star from '../../icons/medals/earlyMedal-1star.png'
import earlyMedal2Star from '../../icons/medals/earlyMedal-2star.png'
import earlyMedal3Star from '../../icons/medals/earlyMedal-3star.png'
import hardMedal1Star from '../../icons/medals/hardMedal-1star.png'
import hardMedal2Star from '../../icons/medals/hardMedal-2star.png'
import hardMedal3Star from '../../icons/medals/hardMedal-3star.png'
import onTimeMedal1Star from '../../icons/medals/onTimeMedal-1star.png'
import onTimeMedal2Star from '../../icons/medals/onTimeMedal-2star.png'
import onTimeMedal3Star from '../../icons/medals/onTimeMedal-3star.png'
import snailIcon from '../../icons/snail_icon.png'

export type MedalImages = {
  [category: string]: {
    [difficulty: string]: string
  }
}
export const getMedalImage = (category: keyof MedalImages, difficulty: string) => {
  const medalImages: MedalImages = {
    hard: {
      EASY: hardMedal1Star,
      MEDIUM: hardMedal2Star,
      HARD: hardMedal3Star,
    },
    early: {
      EASY: earlyMedal1Star,
      MEDIUM: earlyMedal2Star,
      HARD: earlyMedal3Star,
    },
    onTime: {
      EASY: onTimeMedal1Star,
      MEDIUM: onTimeMedal2Star,
      HARD: onTimeMedal3Star,
    },
  }
  return medalImages[category][difficulty]
}

export const SuccessGridItem = ({
  subtaskTitle,
  timeDifference,
  taskTitle,
  category,
  difficulty,
}: {
  subtaskTitle: string
  timeDifference: number
  taskTitle: string | null
  category: string
  difficulty: string
}) => {
  const [openDialog, setOpenInfo] = useState(false)
  const medalImage = getMedalImage(category, difficulty)
  const isMobile = useAppSelector(state => state.layout.isMobileView)

  const formatTimeDifferenceMessage = (timeDifference: number) => {
    if (timeDifference === 0) {
      return 'בדיוק בזמן'
    } else if (timeDifference > 0) {
      return `${timeDifference} ימים לפני הזמן`
    } else {
      // Convert negative number to positive for display
      return `איחרת ב ${Math.abs(timeDifference)} ימים`
    }
  }

  return (
    <Paper
      onClick={() => {
        setOpenInfo(!openDialog)
      }}
      elevation={3}
      sx={{
        minHeight: '12vh',
        width: isMobile ? '90%' : '40vw',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'start',
        alignItems: 'center',
        alignContent: 'center',
        margin: 0.5,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          p: 1,
          width: '100%',
        }}
      >
        <Typography variant="h6">{subtaskTitle}</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'right',
            width: '100%',
            gap: 1,
            mt: 1,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              backgroundColor: '#F9F9F9',
              borderRadius: 1,
              width: 'fit-content',
              maxWidth: '100%',
              wordBreak: 'break-word',
              padding: '2px',
            }}
          >
            {taskTitle}
          </Typography>
          {timeDifference !== null && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: 1,
                padding: '2px',
                width: 'fit-content',
              }}
            >
              <img
                src={timeDifference >= 0 ? boltIcon : snailIcon}
                style={{ width: '1em', height: '1em', marginLeft: '0.3em' }}
                alt={timeDifference < 9 ? 'snail_icon' : 'bolt_icon'}
              />
              <Typography variant="caption">{formatTimeDifferenceMessage(timeDifference)}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <img src={medalImage} style={{ width: isMobile ? '35%' : '20%' }} alt="medal" />
    </Paper>
  )
}
export default SuccessGridItem
