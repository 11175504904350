import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, Grid, IconButton, ListItemIcon, Menu, MenuItem, TextField } from '@mui/material'
import { CreateTaskForm, DeleteTaskRequest, PartialCreateSubtaskRequest, Status } from 'api/dtos'
import { SetState } from 'common/types/types'
import { BottomDrawer } from 'components/BottomDrawer'
import { SubtaskCreationForm } from 'components/SubtaskCreationForm'
import { BottomSection } from 'pages/TasksPage/TaskCreation/BottomSection'
import { TopSection } from 'pages/TasksPage/TaskCreation/TopSection'
import { TaskAlertContext } from 'pages/TasksPage/TasksPage'
import { SharedSubtaskProvider } from 'pages/TasksPage/UseSharedSubtaskContext'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { deleteTaskAsync, selectUserState, updateTaskAsync, useAppDispatch, useAppSelector } from 'store'
import { genUniqueId } from 'utils/utils'
import DeleteDialogPopup from '../DeleteDialogPopup'
import { EditTaskContext } from '../TaskCardContents'
import TaskProgressBar from '../TaskProgressBar'

interface SubtaskTopHalfProps {
  progressBarValue: number
  setArrowDirection: SetState<string>
  arrowDirection: string
}

export const SubtaskTopHalf = (props: SubtaskTopHalfProps) => {
  const { title, taskId, userId } = useContext(EditTaskContext)
  const { user } = useAppSelector(selectUserState)
  const dispatch = useAppDispatch()
  const { alertDeleteTask } = useContext(TaskAlertContext)
  const taskControl = useForm<CreateTaskForm>()
  const inputRef = useRef<HTMLInputElement>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [editedTitle, setEditedTitle] = useState(title)
  const [openBottomDrawer, setOpenBottomDrawer] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [deleteData, setDeleteData] = useState<{ id: number; userId: number } | null>(null)

  const initialUuid = genUniqueId()
  const [subtasksToCreate, setSubTasksToCreate] = useState<PartialCreateSubtaskRequest[]>([
    {
      description: '',
      difficulty: undefined,
      uuid: initialUuid,
      deadline: null,
    },
  ])
  const [expandedUuid, setExpandedUuid] = useState<string | null>(initialUuid)
  const [stepTextFieldValues, setStepTextFieldValues] = useState<{ [key: string]: string }>({})
  const [activeSubtaskUuid, setActiveSubtaskUuid] = useState<string | null>(null)
  const { alertSuccess } = useContext(TaskAlertContext)

  const resetForm = useCallback(() => {
    let newUuid = genUniqueId()
    setSubTasksToCreate([
      {
        description: '',
        difficulty: undefined,
        uuid: newUuid,
        deadline: null,
      },
    ])
    setExpandedUuid(newUuid)
    setStepTextFieldValues({}) // If you want to reset the titleValue state
    taskControl.reset()
    setActiveSubtaskUuid(null)
  }, [taskControl])

  const onSubtaskSubmit = useCallback(
    (data: CreateTaskForm) => {
      const newSubtasks = data.subtasks.map(subtask => ({
        description: subtask.description || '',
        difficulty: subtask.difficulty || 'EASY',
        deadline: subtask.deadline || new Date(),
        status: Status.INPROGRESS,
        startedTime: undefined,
        endTime: undefined,
      }))

      dispatch(
        updateTaskAsync({
          id: taskId,
          title,
          newSubtasks,
          userId,
        }),
      ).then(() => {
        setOpenBottomDrawer(false)
        resetForm()
        alertSuccess('מטרה עודכנה בהצלחה')
      })
    },
    [dispatch, taskId, title, userId, resetForm, alertSuccess],
  )

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus()
    }
  }, [isEditing])

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleEditName = useCallback(() => {
    setIsEditing(true)
    setEditedTitle(title)
  }, [title])

  const handleTitleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedTitle(event.target.value)
  }, [])

  const handleTitleSubmit = useCallback(() => {
    if (editedTitle !== title) {
      dispatch(updateTaskAsync({ id: taskId, title: editedTitle, userId }))
    }
    setIsEditing(false)
  }, [dispatch, taskId, title, editedTitle, userId])

  const handleMenuActions = useCallback(
    (action: 'edit' | 'add' | 'delete') => {
      handleMenuClose()
      if (action === 'edit') {
        setTimeout(handleEditName, 0)
      } else if (action === 'add') {
        setOpenBottomDrawer(true)
      } else if (action === 'delete') {
        setDeleteData({ id: taskId, userId: user.id })
        setIsDialogOpen(true)
      }
    },
    [taskId, user.id, handleEditName, handleMenuClose],
  )

  const handleTaskDelete = useCallback(
    (data: DeleteTaskRequest) =>
      dispatch(deleteTaskAsync({ id: data.id, userId: user.id })).then(() =>
        alertDeleteTask('מטרה נמחקה בהצלחה', data.id),
      ),
    [dispatch, alertDeleteTask, user.id],
  )

  const confirmDelete = useCallback(() => {
    if (deleteData) {
      handleTaskDelete(deleteData)
    }
    setIsDialogOpen(false)
  }, [deleteData, handleTaskDelete])

  const validateAndSubmitTask = useCallback(
    (data: CreateTaskForm) => {
      const incompleteSubtaskIndex = data.subtasks.findIndex(
        subtask => !subtask.description || !subtask.deadline || !subtask.difficulty,
      )

      if (incompleteSubtaskIndex !== -1) {
        setExpandedUuid(subtasksToCreate[incompleteSubtaskIndex].uuid)
      } else {
        onSubtaskSubmit(data)
      }
    },
    [subtasksToCreate, setExpandedUuid, onSubtaskSubmit],
  )

  const handleFormSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      // Trigger form validation
      taskControl.trigger().then(isValid => {
        // Always call validateAndSubmitTask, regardless of form validation
        const formData = taskControl.getValues()
        validateAndSubmitTask(formData)
      })
    },
    [taskControl, validateAndSubmitTask],
  )

  return (
    <SharedSubtaskProvider
      setReinforcements={() => {}}
      subtasksToCreate={subtasksToCreate}
      setSubTasksToCreate={setSubTasksToCreate}
      taskControl={taskControl}
      setOpen={setOpenBottomDrawer}
      onSubmit={onSubtaskSubmit}
      setReinforcementOpen={() => {}}
      reinforcementOpen={false}
      topTitle={editedTitle}
      setTopTitle={setEditedTitle}
      setExpandedUuid={setExpandedUuid}
      stepTextFieldValues={stepTextFieldValues}
      setStepTextFieldValues={setStepTextFieldValues}
      setActiveSubtaskUuid={setActiveSubtaskUuid}
      activeSubtaskUuid={activeSubtaskUuid}
      validateAndSubmit={handleFormSubmit}
      expandedUuid={expandedUuid}
      resetForm={resetForm}
    >
      <Grid container justifyContent={'space-between'} flexDirection={'column'} p={'0.5em 0 0.5em 0'}>
        <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '0.5em' }}>
          <IconButton onClick={handleMenuOpen} size="small">
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem
              onClick={() => {
                handleMenuActions('edit')
              }}
              sx={{ justifyContent: 'flex-end', padding: '0 0 0 0.5em' }}
            >
              שינוי שם
              <ListItemIcon sx={{ ml: 1 }}>
                <ModeEditOutlinedIcon />
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuActions('add')
              }}
              sx={{ justifyContent: 'flex-end', padding: '0 0 0 0.5em' }}
            >
              הוספת משימה
              <ListItemIcon sx={{ ml: 1 }}>
                <AddIcon />
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuActions('delete')
              }}
              sx={{ justifyContent: 'flex-end', padding: '0 0 0 0.5em' }}
            >
              מחיקת מטרה
              <ListItemIcon sx={{ ml: 1 }}>
                <DeleteOutlineOutlinedIcon />
              </ListItemIcon>
            </MenuItem>
          </Menu>
          <Box
            style={{
              fontWeight: 400,
              flexGrow: 1,
              fontSize: '1.3em',
              marginLeft: '1em',
              textAlign: 'right',
              cursor: 'pointer',
            }}
            onClick={handleEditName}
          >
            {isEditing ? (
              <TextField
                inputRef={inputRef}
                value={editedTitle}
                onChange={handleTitleChange}
                onBlur={handleTitleSubmit}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    handleTitleSubmit()
                  }
                }}
                autoFocus
                fullWidth
                variant="standard"
                InputProps={{
                  style: {
                    textAlign: 'right',
                    fontSize: '1em',
                    fontWeight: 400,
                  },
                }}
                onClick={e => e.stopPropagation()}
              />
            ) : (
              title
            )}
          </Box>
          <IconButton
            onClick={() => props.setArrowDirection(props.arrowDirection === 'down' ? 'up' : 'down')}
            size="small"
          >
            {props.arrowDirection === 'down' ? <ExpandMoreIcon /> : <KeyboardArrowLeftIcon />}
          </IconButton>
        </Grid>
        <Grid item xs={5} mb={2}>
          <TaskProgressBar value={props.progressBarValue} />
        </Grid>
        <BottomDrawer
          open={openBottomDrawer}
          height={100}
          handleClose={() => setOpenBottomDrawer(false)}
          Content={
            <>
              <Grid item xs={12}>
                <TopSection
                  onClose={() => setOpenBottomDrawer(false)}
                  setShowTitleOnly={() => {}}
                  resetForm={resetForm}
                />
              </Grid>
              <Box component="form" onSubmit={handleFormSubmit} noValidate>
                <SubtaskCreationForm />
                <BottomSection />
              </Box>
            </>
          }
        />
      </Grid>
      <DeleteDialogPopup
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirmDelete={confirmDelete}
        type={deleteData?.id === taskId ? 'task' : 'subtask'}
      />
    </SharedSubtaskProvider>
  )
}
